import styled from "styled-components";
import { Box as MUIBox } from '@mui/material';


export const Container = styled.div`
  display: flex;
  position: static;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 80px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  background-color: var(--gray);
  /* background-color: var(--green); */
  /* color: var(--); */

  a {
    text-decoration: none;
    color: inherit;
  }

`
export const Wrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  `
export const Box = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`
export const Logo = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 1.4rem;
  }
`
export const Navigation = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 1rem; */
`
export const User = styled.div``
export const NotificationBox = styled.div`
  display: flex;
  align-items: center;
  font-size: .7rem;
  gap: 1rem;
  span {
    background-color: var(--white);
    padding: .2rem;
    border-radius: .5rem;
    font-size: .5rem;
  }
`