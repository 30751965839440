import { motion } from "framer-motion";
import styled from "styled-components";
import { queries } from "../../components/constants";

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  margin-bottom: 3rem;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;
export const Header = styled.div`
  color: var(--green);
  font-size: 1.5rem;
`
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
`
export const Card = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: .5rem;
  padding: 1rem;
  width: 350px;
  border-radius: 1rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  @media ${queries.mobile} {
    width: 90%;
  }
`
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: .9rem;
  }
`
export const CardMedia = styled.img`
  width: 20%;
  border-radius: 1rem;
`

export const CardContent = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`
export const Options = styled.div`
  display: flex;
  flex-direction: column;
`
export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  text-transform: capitalize;
`
export const CardActions = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;  
  justify-content: flex-end;
  width: 100%;
`
export const Group = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const Device = styled.i`
  position: absolute;
  color: rgba(0, 0, 0, 0.07);
  font-size: 15rem;
  bottom: -20%;
  left: -10%;
`

export const NoApps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  color: var(--gray);
`

export const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  padding: 2rem;
  border-radius: .5rem;
  width: 350px;
  display: flex;
  gap: 2rem;
  flex-direction: column;
`
export const ModalHeader = styled.div``