import { createContext, useEffect, useState } from "react";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { getUser } from "../../firebase/store";
const AuthState = createContext();

export default function AuthContext({ children }) {
  const [user, setUser] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (response) => {
      response ? setUser(response) : setUser(null);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = async () => {
      if (user) {
        const { data: docData } = await getUser(user.uid, (snapshot) => {
          setData(snapshot.data());
        });
        setData(docData);
      }
    };
    unsubscribe();
  }, [user]);

  return (
    <AuthState.Provider value={{ user, setUser, data, setData }}>
      {children}
    </AuthState.Provider>
  );
}

export { AuthState };
