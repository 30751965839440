import styled from "styled-components";
import { queries } from "../../components/constants";

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  display: grid;
  place-items: center;
  margin-bottom: 3rem;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  width: 60%;

  @media ${queries.mobile} {
    width: 95%;
  }
`;
export const Card = styled.div`
  padding: 1rem;
  border-radius: 1.5rem;
  background-color: var(--gray);
  height: 100px;
  position: relative;
  display: flex;
  justify-content: space-between;
`
export const CardTimestamp = styled.span`
  position: absolute;
  bottom: -1.2rem;
  font-size: .8rem;
  right: 0;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
`
export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const CardHeader = styled.div`
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);

  @media ${queries.mobile} {
    font-size: .9rem;
  }
`
export const CardActions = styled.div`
  display: flex;
  gap: 1rem;
`
export const Screens = styled.div`
  button {
    height: 100%;
  }
`
export const CardMedia = styled.img`
  height: 100%;
  border-radius: .5rem;
`

export const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  padding: 2rem;
  border-radius: .5rem;
  width: 350px;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  @media ${queries.mobile} {
    width: 70%;
  }
`