import styled from "styled-components";
import { queries } from "../../components/constants";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-height: calc(100vh - 80px);
  background-image: var(--background);

`;
export const Header = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--green);
  h4 {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  @media ${queries.mobile} {
    p {
      width: 90%;
      font-size: .9rem;
    }
    h4 {
      font-size: 1.5rem;
    }
  }
`;
export const Wrapper = styled.div`
  width: 30%;
  @media ${queries.mobile} {
    width: 90%;
  }
`;
export const Services = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
`
export const Or = styled.div`
  align-self: center;
  opacity: .4;
  color: var(--green)
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
`;
export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Other = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

