import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Container, Wrapper, Header, Form, Actions, Other } from "./styled";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorMessage, Loading, RouteContainer } from "../../components";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import { createUser } from "../../firebase/store";

export default function SignUp() {
  const [form, setForm] = useState({
    displayName: "",
    email: "",
    password: "",
    password_confirm: "",
  });
  const [errors, setErrors] = useState({
    email: false,
    displayName: false,
    password: false,
    password_confirm: false,
  });
  const [hideText, setHideText] = useState({
    password: false,
    password_confirm: false,
  });
  const [loading, setLoading] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (name) => {
    setHideText((state) => ({ ...state, [name]: !state[name] }));
  };

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!form.email && !form.password) return;
      if (form.password !== form.password_confirm)
        return setErrors((state) => ({
          ...state,
          password: true,
          password_confirm: true,
        }));
      setLoading(true);
      const response = await createUserWithEmailAndPassword(
        auth,
        form.email,
        form.password
      ).catch((er) => {
        toast.error(ErrorMessage(er));
      });
      if (response?.user) {
        await updateProfile(response?.user, {
          displayName: form.displayName,
        }).catch((er) => {
          ErrorMessage(er);
        });
        await createUser(response.user.uid).catch((er) => {
          toast.error(ErrorMessage(er));
        });
        toast.success("Successfully Signed up");
        navigate("/");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(ErrorMessage(error));
    }
  };

  const OnChangeText = (e) => {
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  if (loading) return <Loading />;
  else
    return (
      <RouteContainer>
        <Container>
          <Header>
            <h4>Sign Up</h4>
          </Header>
          <Wrapper>
            <Form onSubmit={onSubmit}>
              <TextField
                value={form.displayName}
                name="displayName"
                onChange={OnChangeText}
                label="Name"
                variant="outlined"
                autoComplete="name"
                required
              />
              <TextField
                value={form.email}
                name="email"
                type="email"
                onChange={OnChangeText}
                label="Email"
                variant="outlined"
                autoComplete="username"
                required
              />
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={hideText.password ? "text" : "password"}
                  name="password"
                  value={form.password}
                  onChange={OnChangeText}
                  error={errors.password}
                  required
                  autoComplete="new-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("password")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {hideText.password ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password_confirm">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password_confirm"
                  type={hideText.password_confirm ? "text" : "password"}
                  name="password_confirm"
                  value={form.password_confirm}
                  onChange={OnChangeText}
                  error={errors.password_confirm}
                  required
                  autoComplete="new-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          handleClickShowPassword("password_confirm")
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {hideText.password_confirm ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Actions>
                <Box />
                <Button type="submit" variant="contained">
                  Sign UP
                </Button>
              </Actions>
              <Other>
                <Link to="/signin">
                  <Button color="secondary" variant="outlined">
                    Already have account? Sign In
                  </Button>
                </Link>
              </Other>
            </Form>
          </Wrapper>
        </Container>
      </RouteContainer>
    );
}
