import axios from "axios";


export const getGooglePlayInfo = (appId) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/google/${appId}`)
}


export const getAppleInfo = (country_code, name, appId) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/apple/${country_code}/${name}/${appId}`)
}