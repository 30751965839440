import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Container, Wrapper, Header, Form, Actions } from "./styled";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import { ErrorMessage, RouteContainer } from "../../components";

export default function ForgotPassword() {
  const [form, setForm] = useState("");

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!form) return;
      await sendPasswordResetEmail(auth, form).catch((e) =>
        toast.error(ErrorMessage(e))
      );
      toast.success("Reset password email has been sent.");
    } catch (error) {
      toast.error(ErrorMessage(error));
    }
  };

  const OnChangeText = (e) => {
    setForm(e.target.value);
  };

  return (
    <RouteContainer>
      <Container>
        <Header>
          <h4>Forgot You Password</h4>
          <p>
            Enter your email address, and we will send you an email with a link
            to reset your password.
          </p>
        </Header>

        <Wrapper>
          <Form onSubmit={onSubmit}>
            <TextField
              value={form}
              name="email"
              type="email"
              onChange={OnChangeText}
              label="Email"
              variant="outlined"
              autoComplete="username"
              required
            />
            <Actions>
              <Box />
              <Button type="submit" variant="contained">
                Reset
              </Button>
            </Actions>
          </Form>
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}
