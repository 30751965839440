import styled from "styled-components";
import { queries } from "../../components/constants";

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  display: grid;
  place-items: center;
  background-image: var(--background);
  margin-bottom: 3rem;
`;
export const Wrapper = styled.div`
  width: 40%;

  @media ${queries.mobile} {
    width: 90%;
  }
`;
export const Header = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;
export const Questions = styled.div`
  a {
    color: var(--green);
  }
`;
