import styled from "styled-components";
import { Container as MUIContainer } from '@mui/material'

export const Container = styled(MUIContainer)`
  margin: 2rem 0;
  p {
    margin-left: 1rem;
  }
  h3 {
    margin: 2rem 0 0 0;
  }
  a {
    color: var(--green);
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  h2 {
    font-size: 2rem;
  }
  span {
    font-size: .9rem;
    background-color: var(--green);
    padding: .5rem 1rem;
    color: var(--white);
    border-radius: 5rem;
  }
`
