import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import Loading from "./loading";

export default function Protected({ children, requireSignIn }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const timer = useRef(null);

  useEffect(() => {
    setLoading(true);
    if (!user) {
      if (requireSignIn) {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          navigate("/signin");
          setLoading(false);
        }, 1500);
      } else setLoading(false);
    } else {
      if (!requireSignIn) {
        navigate(location.state?.from?.pathname || "/");
        setLoading(false);
      } else {
        clearTimeout(timer.current);
        setLoading(false);
      }
    }
  }, [location, user, requireSignIn, navigate]);

  if (loading) return <Loading fullScreen />;
  else return children;
}
