import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  getFirestore,
  doc,
  onSnapshot,
  setDoc,
  getDoc
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref as StorageRef, uploadBytesResumable } from "firebase/storage";
import { toast } from "react-toastify";

export const createUser = async (uid) => {
  try {
    const db = getFirestore();
    const ref = doc(db, 'users', uid);
    const matches = await getDoc(ref);
    if (matches.exists()) return matches.data();
    return setDoc(doc(db, 'users', uid), {
      uid,
      stars: 2,
      apps: [],
      installs: [],
      approved: [],
      rejected: [],
      store: null,
      holdStars: 0,
    });
  } catch (error) {
    console.log(error);
    toast.error('something went wrong.')
  }
}

export const updateUser = async (uid, data) => {
  try {
    const db = getFirestore();
    const ref = doc(db, 'users', uid);
    const matches = await getDoc(ref);
    if (!matches.exists()) return;
    return updateDoc(ref, data);
  } catch (error) {
    console.log(error);
    toast.error('something went wrong.')
  }
}

export const getUser = async (uid, onUpdate) => {
  try {
    const db = getFirestore();
    const ref = doc(db, 'users', uid);
    const matches = await getDoc(ref);
    if (!matches.exists()) return;
    onSnapshot(ref, onUpdate);
    const data = matches.data()
    return { ref, data }
  } catch (error) {
    console.log(error);
    toast.error('something went wrong.')
  }
}

export const getRandomApp = async (uid, submitted) => {
  try {
    if (!uid) return;
    const db = getFirestore();
    const ref = query(collection(db, 'users'), where('stars', ">=", 1));
    const matches = await getDocs(ref);
    if (!matches.docs.length) return;

    const apps = [...matches.docs.map(x => x.data())]
    const filtered = apps.filter(a => a.uid !== uid && a.apps.length && a.holdStars < a.stars);


    const filter_submitted = filtered.map(a => {
      return {
        ...a,
        apps: [
          ...a.apps.filter(x => !submitted.some(s => s.appId === x.id))
        ]
      }
    });

    console.log(filter_submitted, filtered);

    const users_filter = filter_submitted.filter(a => a.apps.filter(x => x.state).length);

    // !a.submitted.map(s => s.appId).includes(x.id)

    if (!users_filter.length) return;

    const random = Math.floor(Math.random() * users_filter.length);
    const selected_user = users_filter[random];
    const user_apps = selected_user.apps.filter(a => a.state);

    const selected_app = user_apps[Math.floor(Math.random() * user_apps.length)]

    return { user: selected_user, app: selected_app }

  } catch (error) {
    console.log(error);
    toast.error('something went wrong.')
  }
}


export const getApps = async () => {
  try {
    const db = getFirestore();
    const ref = query(collection(db, 'users'), where('apps', "!=", []));
    const matches = await getDocs(ref);
    if (!matches.docs.length) return [];

    const apps = [...matches.docs.map(x => x.data())]
    const filtered = apps.filter(a => a.apps.length);

    const recent_apps = []
    const users_filter = filtered.filter(a => a.apps.filter(x => x.state).length);
    users_filter.map(x => recent_apps.push(...x.apps));
    const recent_added = recent_apps.sort((a, b) => b.createdAt - a.createdAt)

    return recent_added;
  } catch (error) {
    console.log(error);
    toast.error('something went wrong.')
  }
}

export const uploadScreenshot = async (id, url, onComplete) => {
  try {

    const storage = getStorage();
    const storageRef = StorageRef(storage, `screens/${id}.jpg`);
    const metadata = { contentType: "image/jpeg" };

    const file = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        resolve(xhr.response);
      };
      xhr.onerror = () => {
        reject(new TypeError("network error"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", url, true);
      xhr.send(null);
    });


    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on("state_changed", () => {
      // const progress =
      //   Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      console.log('screenshot uploaded');
    }, (error) => {
      console.error(error)
      toast.error('something went wrong.')
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then(onComplete)
    });

  } catch (error) {
    console.log(error);
  }
}


export const updateAppById = async (docId, userId, updated) => {
  try {
    const db = getFirestore();
    const ref = doc(db, 'users', userId);
    const matches = await getDoc(ref);
    if (!matches.exists()) return;
    const userData = { ...matches.data() };
    let docIndex = userData.apps.findIndex(d => d.id === docId);
    if (isNaN(docIndex)) return;


    for (const prop in updated) {
      userData.apps[docIndex][prop] = updated[prop];
    }

    return updateDoc(ref, userData);
  } catch (error) {
    console.log(error);
    toast.error('something went wrong.')
  }

}