import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Navbar, Footer } from "./components";
import routes from "./routes";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./config/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Navbar />
        <div
          style={{
            width: "100%",
            textAlign: "center",
            padding: ".5rem",
            backgroundColor: "var(--red)",
            color: "var(--white)",
          }}
        >
          This is a live demo, the site is still under development
        </div>
        <Routes>
          {routes.map((prop, index) => (
            <Route key={index} path={prop.path} element={prop.element} />
          ))}
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}
