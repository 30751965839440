import React from "react";
import {
  Container,
  Wrapper,
  Author,
  MainSide,
  Logo,
  Box,
  RoutesWrapper,
  Header,
  Navigators,
} from "./styled";
// import Switch from "@mui/material/Switch";
import routes from "./routes";
import { Link } from "react-router-dom";

export default function Footer() {
  // const onThemeChange = () => {
  //   const root = document.querySelector(":root");
  //   const body = document.querySelector("body");
  //   body.style.backgroundColor = "#1F3044";
  //   body.style.color = "#fff";
  //   root.style.setProperty("--white", "#1F3044");
  // };

  return (
    <Container>
      <Wrapper>
        {/* <div>
          <Switch onChange={onThemeChange} color="secondary"></Switch>
        </div> */}
        <MainSide>
          <Logo>
            <h2>ClickToClick</h2>
            <p>The best tool to bootstrap and grow your app</p>
          </Logo>
          <Author>
            © 2022 <a href="https://osmx.me/">OSMX</a> All Rights Reserved.
          </Author>
        </MainSide>
        <RoutesWrapper>
          {routes.map((root, index) => (
            <Box key={index}>
              <Header>{root.name}</Header>
              <Navigators>
                {root.routes.map((prop, key) =>
                  prop?.tag ? (
                    <a key={key} href={prop.path}>
                      {prop.name}
                    </a>
                  ) : (
                    <Link key={key} to={prop.path}>
                      {prop.name}
                    </Link>
                  )
                )}
              </Navigators>
            </Box>
          ))}
        </RoutesWrapper>
      </Wrapper>
    </Container>
  );
}
