import { Button } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Loading, RouteContainer } from "../../components";
import { AuthState } from "../../components/context/AuthContext";
import {
  Container,
  Wrapper,
  Card,
  CardHeader,
  CardContent,
  ModalBox,
  CardMedia,
  Screens,
  CardTimestamp,
  CardActions,
} from "./styled";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { useLocation } from "react-router-dom";

export default function Installs() {
  const { data } = useContext(AuthState);
  const [openModel, setOpenModel] = useState(false);
  const [screenshot, setScreenshot] = useState("");
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById(location.hash.slice(1));
    element.scrollIntoView();
  }, [location.hash]);

  const handleOpen = (screen) => {
    setScreenshot(screen);
    setOpenModel(true);
  };
  const handleClose = () => setOpenModel(false);

  const onApprove = (app) => {};

  if (!data?.installs) return <Loading />;
  else
    return (
      <RouteContainer>
        <Container>
          <Modal
            open={openModel}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModel}>
              <ModalBox>
                <img src={screenshot} alt="" />
              </ModalBox>
            </Fade>
          </Modal>
          <Wrapper>
            {data?.installs?.length &&
              data.installs.map((prop) => (
                <Card key={prop.id} id={prop.id}>
                  <CardContent>
                    <CardHeader>{prop.name}</CardHeader>
                    <CardActions>
                      <Button onClick={onApprove} size="small" variant="text">
                        Approve
                      </Button>
                      <Button size="small" variant="text" color="error">
                        Reject
                      </Button>
                    </CardActions>
                  </CardContent>
                  <Screens>
                    <Button
                      onClick={() => handleOpen(prop.screenshot)}
                      color="secondary"
                    >
                      <CardMedia src={prop.screenshot} />
                    </Button>
                  </Screens>
                  <CardTimestamp>
                    {new Date(prop.createdAt).toLocaleString()}
                  </CardTimestamp>
                </Card>
              ))}
          </Wrapper>
        </Container>
      </RouteContainer>
    );
}
