import { motion } from "framer-motion";
import styled from "styled-components";
import { queries } from '../../components/constants'



export const Container = styled.div``;
export const Wrapper = styled.div``;


export const Land = styled.div`
  height: calc(100vh - 80px);
  background-color: var(--green);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, rgba(0, 0, 0, 0.2) 40%);
  background-attachment: fixed;

  h2 {
    font-size: 5rem;
    text-shadow: 5px 2px var(--dark-blue);
  }
  p {
    font-size: 1.2rem;
    letter-spacing: .6rem;
    text-transform: uppercase;
  }

  @media ${queries.mobile} {
    background-image: repeating-radial-gradient(circle at 0 0, transparent 0, rgba(0, 0, 0, 0.2) 50%);
    h2 {
      font-size: 3rem;
    }
    p {
      font-size: .9rem;
      letter-spacing: .4rem;
    }
  }
`;

export const ReactContainer = styled(motion.div)`
  height: 136px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background-color: var(--dark-blue);
  position: relative;
  @media ${queries.mobile} {
    height: fit-content;
    width: 100%;
  }
`
export const RecentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  width: 80%;

  @media ${queries.mobile} {
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    gap: 1rem;
  }
`;
export const RecentText = styled.h2`
  font-size: 2rem;

  @media ${queries.mobile} {
    font-size: 1.2rem;
  }
`
export const Recent = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media ${queries.mobile} {

  }
`
export const AppIcon = styled(motion.img)`
  height: 70px;
  width: 70px;
  border-radius: 1.1rem;

  @media ${queries.mobile} {
    height: 60px;
    width: 60px;     
  }
`



export const Sections = styled.div`
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-image: var( --background);
  flex-direction: column;
  
`;

export const Section = styled(motion.div)`
  display: flex;
  height: 70vh;
  padding: 2rem;
  align-items: center;
  justify-content: space-between;

  :nth-child(even) {
    flex-direction: row-reverse;
  }
  img {
    height: 70%;
  }
  @media ${queries.mobile} {    
    gap: 2rem;
    flex-direction: column-reverse;
    :nth-child(even) {
      flex-direction: column-reverse;
    }
    img {
      height: 50%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  


  h2 {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--green);
  }
  p {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--dark-blue);
  }

  @media ${queries.mobile} {   
    align-items: center; 
    width: 100%;
    text-align: center;
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: .8rem;
    }
  }
`
