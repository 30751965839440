import React, { useState } from "react";
import { RouteContainer } from "../../components";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, Header, Questions, Wrapper } from "./styled";
import { Link } from "react-router-dom";

export default function Tutorials() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <RouteContainer>
      <Container>
        <Wrapper>
          <Header>How it works ?</Header>
          <Questions>
            <Accordion
              color="secondary"
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Our rules</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>You have to follow the app requirements</li>
                  <li>You have to open the app at least once</li>
                  <li>
                    You have to provide a clean screenshot based on the app
                    requirements
                  </li>
                  <li>
                    You have full rights to skip the app if you don't want to
                    install it
                  </li>
                  <li>Don't rate the app unless you've tried it</li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              color="secondary"
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>How do you get stars?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  you can get stars by just doing what others do for you which
                  is install other apps and give them rates if required or you
                  can buy them from <Link to="/store">Store</Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              color="secondary"
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Steps of getting stars</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    Go to <Link to="/stars">Gain Stars</Link> and pick the app
                    appear to you
                  </li>
                  <li>Install the app and take a screenshot inside the app</li>
                  <li>
                    Check if the author needs you to rate his app to get
                    approval
                  </li>
                  <li>
                    Wait for the app author to approve your screenshot and then
                    you will get 2 or 1 star based on the app
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              color="secondary"
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>How can I add my app?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    Go to <Link to="/add">Add my app</Link>
                  </li>
                  <li>Paste your app link and click verify</li>
                  <li>
                    The base app without any extra options for others to install
                    is 1 star
                  </li>
                  <li>
                    If you want others to rate your app check the option box
                    this will cost you an extra star
                  </li>
                  <li>
                    If you want others to send you a screenshot inside the app
                    check this option box
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              color="secondary"
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  What happens if someone installed my app?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  When someone installs your app you will receive a screenshot
                  from this user and you have to accept it or reject it based on
                  your app requirements for example if you pay an extra star for
                  others to rate your app he must provide a screenshot with his
                  rating message this also applied to you when you install
                  others apps
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Questions>
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}
