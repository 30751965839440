import styled from "styled-components";
import { queries } from "../../components/constants";

export const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
`;
export const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 70%;

  @media ${queries.mobile} {
    width: 100%;
    margin-bottom: 5rem;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  text-align: center;
  gap: 1rem;

  h2 {
    font-size: 2rem;
    color: var(--green);
    text-transform: uppercase;
  }
  p {
    color: var(--green);
    width: 60%;
  }
  @media ${queries.mobile} {
    p {
      width: 80%;
    }
  }
`
export const Form = styled.form`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  gap: 1rem;
  @media ${queries.mobile} {
    width: 80%;
  }
`
export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${queries.mobile} {
    font-size: 0.7rem;
  }

`
export const Label = styled.div``
export const Cost = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;
  @media ${queries.mobile} {
    font-size: 1rem;
  }
`

export const Match = styled.div`
  font-size: .7rem;
  color: var(--gray);
  margin-top: 1rem;
  @media ${queries.mobile} {
    font-size: 0.5rem;
  }
`
export const Spot = styled.span`
  color: var(--green);
  font-weight: bold;
`
export const Apple = styled.div``
export const Google = styled.div``
export const VerifiedPreview = styled.div`
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 1rem;
  img {
    border-radius: 1rem;
    height: 100%;
  }
  p {
    font-size: 1em;
  }
  @media ${queries.mobile} {
    width: 90%;
    gap: 1rem;
    min-height: 100px;
    height: fit-content;
    flex-direction: column;

    img {
      height: 50%;
      width: 30%;
    }
    p {
      font-size: 1rem;
    }
  }
`