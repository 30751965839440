import { Input } from "@mui/material";
import styled from "styled-components";
import { queries } from "../../components/constants";

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${queries.mobile} {
    margin: 4rem;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: .5rem;
  padding: 1rem;
  width: 350px;
  border-radius: 1rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.1);

  @media ${queries.mobile} {
    width: 90%;
  }
`;
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  span {
    font-size: .9rem;
  }
`;


export const Requirements = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: center;
  p {
    font-size: .8rem;
  }
  
  div {
    font-size: .8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
export const CardMedia = styled.img`
  width: 20%;
  border-radius: 1rem;
`;
export const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-top: 1rem;
  gap: 2rem;
`

export const ContentGroup = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: center;
  p {
    font-size: .8rem;
  }
`;
export const CardActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  padding: 2rem;
  border-radius: .5rem;
  width: 350px;
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const ScreenUploader = styled(Input)``
export const Available = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  
  p {
    color: var(--l-gray);
    font-size: 1.5rem;
  }
  button {
    transition: 300ms;
    :hover {
      transform: rotateZ(-180deg);
    }
  }
`