import React from "react";
import { RouteContainer } from "../../components";
import styled from "styled-components";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import ErrorImg from "../../assets/error.png";
export default function NotFound() {
  return (
    <RouteContainer>
      <Container>
        <Wrapper>
          <Error src={ErrorImg}></Error>
          <Title>Oh no, something went wrong.</Title>
          <Subject>
            It looks like we are experiencing some problems on our end.
          </Subject>
          <ButtonLink to="/">
            <Button variant="outlined">Back Home</Button>
          </ButtonLink>
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Error = styled.img`
  font-size: 12rem;
  width: 20%;
  color: var(--green);
  text-shadow: 0 0 15px rgba(115, 230, 167, 0.5),
    0 0 40px rgba(115, 230, 167, 0.5), 0 0 90px rgba(115, 230, 167, 0.5);
`;

export const Title = styled.p`
  font-size: 1rem;
  color: var(--green);
  font-weight: bold;
`;

export const Subject = styled.p`
  font-size: 0.8rem;
  color: var(--green);
`;

export const ButtonLink = styled(Link)`
  margin-top: 1rem;
`;
