import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, RouteContainer } from "../../components";
import { AuthState } from "../../components/context/AuthContext";
import {
  Container,
  Wrapper,
  Header,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Content,
  NoApps,
  CardMedia,
  Options,
  Option,
  Group,
  Device,
} from "./styled";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import { updateAppById, updateUser } from "../../firebase/store";
import { arrayRemove } from "firebase/firestore";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

export default function AppsList() {
  const { user, data } = useContext(AuthState);
  const [apps, setApps] = useState(data.apps || []);

  useEffect(() => {
    setApps(data.apps || []);
  }, [data]);

  const onDeleteApp = async (app) => {
    try {
      await updateUser(user.uid, {
        apps: arrayRemove(app),
      })
        .catch((error) => toast.error(ErrorMessage(error)))
        .then(() => {
          toast.success("App deleted.");
        });
    } catch (error) {
      toast.error(ErrorMessage(error));
    }
  };

  const onUpdateState = (app) => {
    try {
      updateAppById(app.id, user.uid, {
        state: !app.state,
      }).catch((error) => toast.error(ErrorMessage(error)));
    } catch (error) {
      console.log(error);
      toast.error(ErrorMessage(error));
    }
  };

  return (
    <RouteContainer>
      <Container>
        <Wrapper>
          <Header>
            <h2>My Apps</h2>
          </Header>
          <Content>
            {apps.length ? (
              apps.map((prop, index) => (
                <Card
                  key={index}
                  initial={{ opacity: 0, x: 100 }}
                  whileInView={{ opacity: [0, 0, 1], x: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <CardHeader>
                    <span>{prop.name}</span>
                    <CardMedia src={prop.icon} />
                  </CardHeader>
                  <CardContent>
                    <Options>
                      <Option>
                        <h6>App State</h6>
                        <span>
                          <Switch
                            onChange={() => onUpdateState(prop)}
                            size="small"
                            checked={prop.state || false}
                          />
                        </span>
                      </Option>
                      <Option>
                        <h6>Spent stars </h6>
                        <span>{prop.stars || 0}</span>
                      </Option>
                      <Option>
                        <h6>installed</h6>
                        <span>{prop.installed || 0}</span>
                      </Option>
                      <Option>
                        <h6>skipped</h6>
                        <span>{prop.skipped || 0}</span>
                      </Option>
                      <Option>
                        <h6>Presented</h6>
                        <span>{prop.presented || 0}</span>
                      </Option>
                    </Options>
                  </CardContent>
                  <CardActions>
                    <Group>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => onDeleteApp(prop)}
                      >
                        Delete
                      </Button>
                    </Group>
                  </CardActions>
                  <Device
                    className={
                      prop.device === "ios"
                        ? "fa-brands fa-apple"
                        : "fa-brands fa-android"
                    }
                  />
                </Card>
              ))
            ) : (
              <NoApps>
                <h2>No Apps added yet.</h2>
                <Link to="/add">
                  <Button
                    variant="outlined"
                    color="inherit"
                    startIcon={<AddIcon />}
                  >
                    Add My App Now
                  </Button>
                </Link>
              </NoApps>
            )}
          </Content>
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}
