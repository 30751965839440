import React, { useEffect, useState } from "react";
import {
  Currency,
  ErrorMessage,
  Loading,
  RouteContainer,
  useAuth,
} from "../../components";
import {
  getRandomApp,
  updateAppById,
  updateUser,
  uploadScreenshot,
} from "../../firebase/store";
import {
  Available,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  ContentGroup,
  ModalBox,
  Requirements,
  ScreenUploader,
  Wrapper,
} from "./styled";
import { QRCodeSVG } from "qrcode.react";
import { Button, Checkbox, IconButton } from "@mui/material";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import DownloadingIcon from "@mui/icons-material/Downloading";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { v4 as uuidV4 } from "uuid";
import { arrayUnion, increment } from "firebase/firestore";
import { toast } from "react-toastify";
import LoopIcon from "@mui/icons-material/Loop";

export default function GetStars() {
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(false);
  const [appOwner, setAppOwner] = useState({});
  const { user, data } = useAuth();
  const [skip, setSkip] = useState(0);
  const [openInstallModal, setOpenInstallModal] = useState(false);
  const [submission, setSubmission] = useState({
    screenshot: "",
    id: "",
    userId: "",
    cost: 0,
  });
  const [available, setAvailable] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (user?.uid && data?.submitted) {
        const response = await getRandomApp(user?.uid, data?.submitted);
        if (response) {
          setApp(response.app);
          setAppOwner(response.user);
          setAvailable(false);
        } else setAvailable(true);
      }
      setLoading(false);
    })();
  }, [user, skip, data]);

  const onSkipApp = async () => {
    try {
      setSkip(Math.random());
      updateAppById(app.id, appOwner.uid, {
        skipped: app.skipped + 1,
      });
    } catch (error) {
      toast.error(ErrorMessage(error));
      console.error(error);
    }
  };

  const onInstallApp = () => {
    setOpenInstallModal((state) => !state);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const id = uuidV4();
      await uploadScreenshot(id, submission.screenshot, async (screenshot) => {
        await updateUser(appOwner.uid, {
          installs: arrayUnion({
            userId: user.uid,
            cost: app.requireStars,
            screenshot,
            name: app.name,
            uri: app.uri,
            createdAt: Date.now(),
            id,
            appId: app.id,
          }),
          holdStars: increment(app.requireStars),
        }).catch((e) => toast.error(ErrorMessage(e)));
        await updateUser(user.uid, {
          submitted: arrayUnion({
            appId: app.id,
            createdAt: Date.now(),
          }),
        });
      }).catch((e) => toast.error(ErrorMessage(e)));
      setLoading(false);
      setOpenInstallModal(false);
      toast.success("Your install has been submitted");
      setSkip(Math.random());
    } catch (error) {
      toast.error(ErrorMessage(error));
      console.error(error);
    }
  };

  if (available)
    return (
      <RouteContainer>
        <Container>
          <Available>
            <p>No apps available for you right now</p>
            <IconButton
              onClick={() => {
                setAvailable(false);
                setSkip(Math.random());
              }}
            >
              <LoopIcon />
            </IconButton>
          </Available>
        </Container>
      </RouteContainer>
    );
  else if (loading) return <Loading />;
  else
    return (
      <RouteContainer>
        <Container>
          <Wrapper>
            <Modal
              open={openInstallModal}
              onClose={onInstallApp}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openInstallModal}>
                <ModalBox>
                  <CardHeader>
                    <CardMedia src={app.icon} />
                    <span>{app.name}</span>
                  </CardHeader>
                  <ContentGroup>
                    <p>Scan the QR code for web</p>
                    <QRCodeSVG value={app.uri} />
                  </ContentGroup>
                  <ContentGroup>
                    <p>Or download it if you are using you phone</p>
                    <a href={app.uri} target="_blank" rel="noreferrer">
                      <Button>Go to Store</Button>
                    </a>
                  </ContentGroup>

                  <ScreenUploader
                    type="file"
                    required
                    id="img"
                    name="screenshot"
                    accept="image/*"
                    onChange={(e) =>
                      setSubmission((state) => ({
                        ...state,
                        screenshot: URL.createObjectURL(e.target.files[0]),
                      }))
                    }
                  />
                  <Button variant="contained" onClick={onSubmit}>
                    Submit
                  </Button>
                </ModalBox>
              </Fade>
            </Modal>
            <Card>
              <CardHeader>
                <CardMedia src={app.icon} />
                <span>{app.name}</span>
              </CardHeader>
              <CardContent>
                <Currency amount={app.requireStars} />
                {(app.requireOpen || app.requireRate) && (
                  <Requirements>
                    <p>Requirements</p>
                    {app.requireOpen && (
                      <div>
                        <span>You must take a screenshot inside the app</span>
                        <Checkbox size="small" checked={true} />
                      </div>
                    )}
                    {app.requireRate && (
                      <div>
                        <span>You must rate the app</span>
                        <Checkbox size="small" checked={true} />
                      </div>
                    )}
                  </Requirements>
                )}
                <ContentGroup>
                  <p>Check the app before install</p>
                  <a href={app.uri} target="_blank" rel="noreferrer">
                    <Button>Go to Store</Button>
                  </a>
                </ContentGroup>
                <CardActions>
                  <Button onClick={onInstallApp} endIcon={<DownloadingIcon />}>
                    Install
                  </Button>
                  <Button onClick={onSkipApp} endIcon={<SkipNextIcon />}>
                    Skip this app
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Wrapper>
        </Container>
      </RouteContainer>
    );
}
