import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Container,
  Wrapper,
  Navigation,
  Box,
  Logo,
  NotificationBox,
} from "./styled";
import AdbIcon from "@mui/icons-material/Adb";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { AuthState } from "../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import ErrorMessage from "../constants/errors";
import Currency from "../Currency";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import NotificationSound from "../../assets/notification-sound.mp3";
import { routes } from "./routes";
import AppsIcon from "@mui/icons-material/Apps";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

export default function Navbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [notification, setNotification] = useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user, data } = useContext(AuthState);
  const audioPlayer = useRef(null);
  const queueLength = useRef(0);
  const onMount = useRef(false);

  useEffect(() => {
    try {
      if (!data?.installs?.length) return;
      if (!onMount.current) {
        queueLength.current = data?.installs?.length || 0;
        onMount.current = true;
      } else {
        if (queueLength.current < data.installs.length || 0) {
          audioPlayer.current.play();
          queueLength.current = data?.installs?.length || 0;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [data]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleOpenNotification = (event) => {
    setNotification(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setNotification(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (button) => {
    setAnchorElUser(null);
    if (button.path) navigate(button.path);
    if (button.name === "Logout") {
      button.onClick();
      navigate("/");
    }
  };

  const handleLogout = () => {
    try {
      signOut(auth).catch((e) => ErrorMessage(e));
    } catch (error) {}
  };

  const settings = [
    { name: "My Apps", path: "/apps", icon: <AppsIcon color="inherit" /> },
    {
      name: "Settings",
      path: "/settings",
      icon: <SettingsIcon color="inherit" />,
    },
    {
      name: "Logout",
      onClick: handleLogout,
      icon: <LogoutIcon color="inherit" />,
    },
  ];

  return (
    <Container>
      <Wrapper>
        <audio ref={audioPlayer} src={NotificationSound} />
        <Box>
          <IconButton
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {routes.map((prop, index) => (
              <NavLink to={prop.path} key={index} onClick={handleCloseNavMenu}>
                <MenuItem>
                  <Typography textAlign="center">{prop.name}</Typography>
                </MenuItem>
              </NavLink>
            ))}
          </Menu>
          <Logo>
            <AdbIcon />
            <Link to="/">
              <h1>ClickToClick</h1>
            </Link>
          </Logo>
          <Navigation sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            {routes.map((prop, index) => (
              <NavLink to={prop.path} key={index}>
                <Button variant="text" color="inherit">
                  {prop.name}
                </Button>
              </NavLink>
            ))}
          </Navigation>
        </Box>
        {user ? (
          <Box>
            <Currency amount={data.stars} />
            <IconButton
              onClick={handleOpenNotification}
              size="large"
              aria-label="installs notification"
            >
              <Badge badgeContent={data?.installs?.length || 0} color="primary">
                <MailIcon />
              </Badge>
            </IconButton>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-notification"
              anchorEl={notification}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(notification)}
              onClose={handleCloseNotification}
            >
              {data?.installs?.length &&
                data?.installs.map((prop, index) => (
                  <Link
                    key={index}
                    to={`/installs#${prop.id}`}
                    onClick={handleCloseNotification}
                  >
                    <MenuItem>
                      <NotificationBox>
                        <p>New app install waiting for approval</p>
                        <span>
                          {new Date(prop.createdAt).toLocaleTimeString()}
                        </span>
                      </NotificationBox>
                    </MenuItem>
                  </Link>
                ))}
            </Menu>

            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={user?.displayName} src={user?.photoURL} />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleCloseUserMenu(setting)}
                  sx={{ p: 0, m: 0 }}
                >
                  <Button
                    color={setting.name === "Logout" ? "error" : "inherit"}
                    sx={{ pl: 3, pr: 3 }}
                    fullWidth
                    startIcon={setting.icon}
                  >
                    {setting.name}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ) : (
          <Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <NavLink to="signin">
                <Button variant="contained" color="inherit">
                  Sign In
                </Button>
              </NavLink>
              <NavLink to="signup">
                <Button variant="contained">Sign Up</Button>
              </NavLink>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <NavLink to="signup">
                <Button variant="text" color="inherit">
                  Join Now
                </Button>
              </NavLink>
            </Box>
          </Box>
        )}
      </Wrapper>
    </Container>
  );
}
