import "./style.css";

export default function Loading({ fullScreen, fit }) {
  return (
    <div
      className={
        fullScreen ? "container fullscreen" : fit ? "fit" : "container"
      }
    >
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
