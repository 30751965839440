import React, { useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import styled from "styled-components";
import AnimatedNumbers from "react-animated-numbers";

export default function Currency({ amount = 0 }) {
  const [num, setNum] = React.useState(0);
  useEffect(() => {
    setNum(amount);
  }, [amount]);

  return (
    <Container>
      <AnimatedNumbers animateToNumber={num}></AnimatedNumbers>
      <StarIcon color="inherit" />
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gold);

  font-size: 1.9rem;
`;
