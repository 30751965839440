import { Button, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Currency,
  ErrorMessage,
  Loading,
  RouteContainer,
  useAuth,
} from "../../components";
import {
  Container,
  Wrapper,
  Match,
  Apple,
  Google,
  Header,
  Form,
  Spot,
  FormGroup,
  Label,
  Cost,
  VerifiedPreview,
} from "./styled";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getGooglePlayInfo, getAppleInfo } from "../../api";
import LoadingButton from "@mui/lab/LoadingButton";
import { updateUser } from "../../firebase/store";
import { v4 as uuidV4 } from "uuid";
import { arrayUnion } from "firebase/firestore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function AddApp() {
  const [cost, setCost] = useState(0);
  const { user } = useAuth();
  const [form, setForm] = useState({
    uri: "",
    rate: false,
    openApp: false,
    verified: false,
  });

  const [verify, setVerify] = useState(false);
  const [appPreview, setAppPreview] = useState(0);
  const [app, setApp] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let i = 0;
    if (form.uri) i++;
    if (form.rate) i++;
    setCost(i);
  }, [form]);

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      const genId = uuidV4();
      if (!form.verified)
        return toast.error("Please verify your app before adding it.");
      if (!form.uri) return;
      setLoading(true);
      await updateUser(user.uid, {
        apps: arrayUnion({
          id: genId,
          name: app.name,
          state: true,
          installed: 0,
          skipped: 0,
          presented: 0,
          icon: app.icon,
          stars: 0,
          requireStars: cost,
          createdAt: Date.now(),
          device: form.uri.includes("play.google") ? "android" : "ios",
          uri: form.uri,
          requireRate: form.rate,
          requireOpen: form.openApp,
        }),
      }).catch((e) => toast.error(ErrorMessage(e)));
      toast.success("Your app has been submitted successfully");
      navigate("/apps");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(ErrorMessage(error));
      console.error(error);
    }
  };

  const onFormChange = (e) => {
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const onVerifyApp = async () => {
    try {
      if (!form.uri) return;
      setVerify(true);
      setAppPreview(1);
      var response;
      if (form.uri.includes("play.google")) {
        const appId = form.uri.split("?id=").pop();
        response = await getGooglePlayInfo(appId);
      } else if (form.uri.includes("apps.apple")) {
        const area_of_interest = form.uri.split(".com/").pop();
        const props = area_of_interest.split("/");
        response = await getAppleInfo(props[0], props[2], props[3]);
      }
      if (!response) return setAppPreview(3);
      if (response?.data) {
        setApp(response?.data);
        setForm((state) => ({ ...state, verified: true }));
        setAppPreview(2);
      }
      setVerify(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setVerify(false);
      setAppPreview(0);
    }
  };

  const onRemoveApp = () => {
    setAppPreview(0);
    setForm((state) => ({ ...state, verified: false }));
    setApp({});
  };

  const renderApp = () => {
    if (appPreview === 1)
      return (
        <VerifiedPreview>
          <Loading fit />
        </VerifiedPreview>
      );
    else if (appPreview === 2)
      return (
        <VerifiedPreview>
          <img src={app?.icon} alt={app?.name} />
          <p>{app?.name}</p>
          <IconButton onClick={onRemoveApp} color="error">
            <HighlightOffIcon />
          </IconButton>
        </VerifiedPreview>
      );
    else if (appPreview === 3)
      return (
        <VerifiedPreview>
          <p>App not found.</p>
        </VerifiedPreview>
      );
  };

  return (
    <RouteContainer>
      <Container>
        <Wrapper>
          <Header>
            <h2>Add your app</h2>
            <p>
              Start pushing your app to the world and see it from others' eyes
            </p>
          </Header>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <TextField
                variant="outlined"
                label="App Link"
                fullWidth
                value={form.uri}
                name="uri"
                onChange={onFormChange}
                required
                disabled={form.verified}
              />
              <LoadingButton
                loading={verify}
                disabled={form.verified}
                variant="text"
                onClick={onVerifyApp}
              >
                Verify
              </LoadingButton>
              <Match>
                <Apple>
                  https://apps.apple.com/<Spot>country_code</Spot>/app/
                  <Spot>app_name</Spot>/<Spot>app_id</Spot>
                </Apple>
                <Google>
                  https://play.google.com/store/apps/details?id=
                  <Spot>app_id</Spot>
                </Google>
              </Match>
            </FormGroup>
            <FormGroup>{renderApp()}</FormGroup>
            <FormGroup
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Label>
                Do you want others to rate your app after install (this will
                require more stars)
              </Label>
              <Checkbox
                checked={form.rate}
                onChange={(e) =>
                  setForm((state) => ({
                    ...state,
                    [e.target.name]: !state.rate,
                  }))
                }
                name="rate"
              />
            </FormGroup>
            <FormGroup
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Label>Must take a screenshot inside the app</Label>
              <Checkbox
                checked={form.openApp}
                onChange={(e) =>
                  setForm((state) => ({
                    ...state,
                    [e.target.name]: !state.openApp,
                  }))
                }
                name="openApp"
              />
            </FormGroup>
            <Cost>
              Per install cost <Currency amount={cost} />
            </Cost>
            <Link to="/tutorials" style={{ color: "var(--green)" }}>
              How to get stars ?
            </Link>
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              fullWidth
            >
              Add
            </LoadingButton>
          </Form>
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}
