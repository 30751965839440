import Home from "./home";
import SignIn from "./authentication/SignIn";
import SignUp from "./authentication/SignUp";
import ForgotPassword from "./authentication/ForgotPassword";
import NotFound from "./notFound";
import AddApp from "./addApp";
import Privacy from "./privacy";
import { Protected } from "../components";
import AppsList from "./appsList";
import Tutorials from "./tutorials";
import GetStars from "./getStars";
import Installs from "./installs";
import Settings from "./settings";

const routes = [
  {
    name: "Home",
    path: "/",
    element: <Home />,
  },
  {
    name: "Sign In",
    path: "/signin",
    element: (
      <Protected>
        <SignIn />
      </Protected>
    ),
  },
  {
    name: "Sign Up",
    path: "/signup",
    element: (
      <Protected>
        <SignUp />
      </Protected>
    ),
  },
  {
    name: "Forgot Password",
    path: "/password-rest",
    element: <ForgotPassword />,
  },
  {
    name: "Add New App",
    path: "/add",
    element: (
      <Protected requireSignIn>
        <AddApp />
      </Protected>
    ),
  },
  {
    name: "Settings",
    path: "/settings",
    element: (
      <Protected requireSignIn>
        <Settings />
      </Protected>
    ),
  },
  {
    name: "Installs",
    path: "/installs",
    element: (
      <Protected requireSignIn>
        <Installs />
      </Protected>
    ),
  },
  {
    name: "Privacy Policy",
    path: "/privacy",
    element: <Privacy />,
  },
  {
    name: "Apps",
    path: "/apps",
    element: (
      <Protected requireSignIn>
        <AppsList />
      </Protected>
    ),
  },
  {
    name: "Tutorials",
    path: "/tutorials",
    element: <Tutorials />,
  },
  {
    name: "Get Stars",
    path: "/install-app",
    element: (
      <Protected requireSignIn>
        <GetStars />
      </Protected>
    ),
  },
  {
    name: "Not Found",
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
