import { Tab, Tabs } from "@mui/material";
import React from "react";
import { RouteContainer } from "../../components";
import { Container, Wrapper } from "./styled";

export default function Settings() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <RouteContainer>
      <Container>
        <Wrapper>
          
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}
