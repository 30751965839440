const routes = [
  {
    name: "Plugins",
    routes: [
      {
        name: "Add your app",
        path: "/add",
      },
      {
        name: "Advertise",
        path: "/advertise",
      },
      {
        name: "Explore",
        path: "/explore",
      },
      {
        name: "Get Stars",
        path: "/install-app",
      },
    ],
  },
  {
    name: "Company",
    routes: [
      {
        name: "Careers",
        path: "mailto:itsosmx@gmail.com",
        tag: "a",
      },
      {
        name: "Terms of use",
        path: "/terms",
      },
      {
        name: "Privacy Policy",
        path: "/privacy",
      },
      {
        name: "Contact",
        path: "/contact",
      },
    ],
  },
];

export default routes;
