import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import {
  Container,
  Wrapper,
  Header,
  Form,
  Actions,
  Other,
  Services,
  Or,
} from "./styled";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../firebase";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ErrorMessage, Loading, RouteContainer } from "../../components";
import { toast } from "react-toastify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import { createUser } from "../../firebase/store";

export default function SignIn() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [hideText, setHideText] = useState(false);
  const location = useLocation();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setHideText((state) => !state);
  };

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!form.email && !form.password) return;
      setLoading(true);
      const response = await signInWithEmailAndPassword(
        auth,
        form.email,
        form.password
      ).catch((e) => {
        toast.error(ErrorMessage(e));
      });
      if (response?.user) navigate("/");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      setLoading(true);
      const response = await signInWithPopup(auth, provider).catch((e) => {
        toast.error(ErrorMessage(e));
      });
      if (response?.user) {
        await createUser(response.user.uid).catch((e) => {
          toast.error(ErrorMessage(e));
        });
        navigate(location.state?.from?.pathname || "/");
        window.location.reload();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSignInWithFacebook = async () => {
    try {
      const provider = new FacebookAuthProvider();
      setLoading(true);
      const response = await signInWithPopup(auth, provider).catch((e) => {
        toast.error(ErrorMessage(e));
      });
      if (response?.user) {
        await createUser(response.user.uid).catch((e) => {
          toast.error(ErrorMessage(e));
        });
        navigate("/");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const OnChangeText = (e) => {
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  if (loading) return <Loading fullScreen />;
  else
    return (
      <RouteContainer>
        <Container>
          <Header>
            <h4>Sign In</h4>
          </Header>
          <Wrapper>
            <Services>
              <Button
                onClick={handleSignInWithGoogle}
                variant="outlined"
                startIcon={<GoogleIcon />}
              >
                Sign in with Google
              </Button>
              <Button
                onClick={handleSignInWithFacebook}
                variant="outlined"
                startIcon={<FacebookIcon />}
              >
                Sign in with Facebook
              </Button>
              <Or>---------------- or ----------------</Or>
            </Services>
            <Form onSubmit={onSubmit}>
              <TextField
                value={form.email}
                name="email"
                onChange={OnChangeText}
                label="Email"
                variant="outlined"
                type="email"
                required
                autoComplete="username"
              />
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={hideText ? "text" : "password"}
                  name="password"
                  value={form.password}
                  onChange={OnChangeText}
                  required
                  autoComplete="current-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {hideText ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Actions>
                <Link to="/password-rest">
                  <Button variant="text">forgot your password?</Button>
                </Link>
                <Button type="submit" variant="contained">
                  Sign In
                </Button>
              </Actions>
              <Other>
                <Link to="/signup">
                  <Button color="secondary" variant="outlined">
                    Dont have account? Sign Up
                  </Button>
                </Link>
              </Other>
            </Form>
          </Wrapper>
        </Container>
      </RouteContainer>
    );
}
