import React, { useEffect, useState } from "react";
import { RouteContainer } from "../../components";
import steppingUp from "../../assets/steppingUp.svg";
import goodTeam from "../../assets/goodTeam.svg";
import {
  Container,
  Wrapper,
  Section,
  Land,
  Content,
  Sections,
  RecentWrapper,
  Recent,
  RecentText,
  ReactContainer,
  AppIcon,
} from "./styled";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { getApps } from "../../firebase/store";

export default function Home() {
  const [recent, setRecent] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getApps();
      if (response) setRecent(response.slice(0, 5));
    })();
  }, []);

  return (
    <RouteContainer>
      <Container>
        <Wrapper>
          <Land>
            <h2>Click To Click</h2>
            <p>Give your app the first push</p>
            <Link to="/signin">
              <Button color="inherit" size="large" variant="outlined">
                Get Started
              </Button>
            </Link>
          </Land>
          {recent.length > 0 && (
            <ReactContainer>
              <RecentWrapper>
                <RecentText>Recently Added Apps</RecentText>
                <Recent>
                  {recent.map((prop) => (
                    <AppIcon
                      src={prop.icon}
                      key={prop.id}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: [0, 0, 1], x: [100, 50, 0] }}
                      transition={{ duration: 1 }}
                    />
                  ))}
                </Recent>
              </RecentWrapper>
            </ReactContainer>
          )}
          <Sections>
            <Section
              initial={{ opacity: 0 }}
              whileInView={{ opacity: [0, 0, 1] }}
              transition={{ duration: 1 }}
            >
              <Content>
                <h2>First step to grows</h2>
                <p>
                  Grow your apps and games with us. Turn your idea into a
                  success story.
                </p>
              </Content>
              <img src={steppingUp} alt="stepping up" />
            </Section>
            <Section
              initial={{ opacity: 0 }}
              whileInView={{ opacity: [0, 0, 1] }}
              transition={{ duration: 1 }}
            >
              <Content>
                <h2>Simple, Easy and Fast</h2>
                <p>
                  it only takes less than a minute to get started and get a real
                  app downloads <br /> sign in, add your app, gain some stars,
                  and let the world see your app
                </p>
              </Content>
              <img src={goodTeam} alt="goodTeam" />
            </Section>
          </Sections>
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}
