import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#01875f"
    },
    secondary: {
      main: "#1F3044"
    },
    gold: {
      main: "#eaa92e"
    }
  }
});


export default theme;